import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import {
  CardTitle, Text2, Text2M, Text3M, Text4, Text4M,
} from '../genericComponents/texts';
import selloAutofact from '../assets/img/selloAutofact.svg';
import { BlankSpace } from '../genericComponents/layouts';
import ComparativeBar from '../genericComponents/ComparativeBar';
import checkImg from '../assets/img/check.svg';
import calendarImg from '../assets/img/calendar.svg';
import infoImg from '../assets/img/info.svg';
import kilometerClockImg from '../assets/img/kilometerClock.svg';
import carMoneyImg from '../assets/img/carMoney.svg';
import carSolid from '../assets/img/car-solid.svg';
import { priceFormat, roundPrice } from '../priceFormat';
import RegularPrices from '../models/regularPrices.model';
import Publications from '../models/publications.model';
import BarChart from '../components/BarChart';
import PublicationsList from '../models/publicationsList.model';
import useMediaQuery from '../useMediaQuery';
import RankingChileautos from '../models/rankingChileautos.model';

const Container = styled.div`
  padding: 10px 0;
  display: flex;
  flex-wrap: nowrap;  
`;

const ContainerParent = styled.div`    
  align-items: center;
`;
const PaddingContainer = styled.div`
  padding: 0px 10px;
`;
const RecommendedPrice = styled.div`
  max-width: 280px;
  padding: 10px;
  margin: auto;
  margin-top: 40px;
  border-radius: 10px;
  background: #EBF1F5;
  display: flex;
  flex-direction: row;

  @media(max-width: 700px){
    margin-top: 20px;

  }
`;
const SubCont1 = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row; 
  min-width: 180px;
  padding: 0px 0px;
`;

const SubCont3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 120px;
  margin-left: 30px;
`;
const SubCont4 = styled.div`
  display: flex;
  flex-direction: column;
`;
const SubCont5 = styled.div`
  display: flex;
  flex-direction: row;
`;
const SubCont6 = styled.div`
  display: flex;
  flex-direction:column;
  flex:1;
`;
const SubCont7 = styled.div`
  display:flex;
  flex-wrap: wrap;
  flex: 1;
`;
const ContChart = styled.div`
  display: flex;
  flex:1;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0px;
`;
const SubCont8 = styled.div`
  flex: 1;
  @media (max-width: 700px){
    border-top: 2px solid  #EBF1F5;
    padding-top: 10px;
    margin-top: 10px;
    width: 100%;
    flex: none;
  }
`;
const LineDSK = styled.div`
  width: 100%;
  height: 1px;
  background-color:#EBF1F5;
  @media (max-width: 700px){
    display: none;
  }
`;
const CarFetailsCont = styled.div`
  width: 95%;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
`;
const CarFetailsSubCont = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`;
const CarFetailsSubCont2 = styled.div`
  display: flex;
  flex-direction: row;
`;
const CarPriceCont = styled.div`
  width: 100%;
  margin: 0px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Price = styled.label`
  font-size: 10px;
  color: #074bca;
  font-weight: ${({ fontWeight }: { fontWeight: string }) => fontWeight}; 
  margin: auto;
`;

interface IData {
  label: string;
  value: number;
}

interface Props{
  // eslint-disable-next-line @typescript-eslint/ban-types
  setAutofactStampVisible: Function;
  actualResidualPrice: number;
  referenceMileage: number;
  prices:RegularPrices;
  publication:Publications;
  rankingChileautos:RankingChileautos;
}

let publicationsList:PublicationsList[] = [];

export default function MarketPrice(props:Props) {
  const {
    referenceMileage, actualResidualPrice, prices, publication, setAutofactStampVisible, rankingChileautos,
  } = props;

  if (publication.list) {
    publicationsList = publication.list;
  }

  return (
    <ContainerParent>
      <Container>
        <SubCont6>
          <CardTitle>
            <Text3M>Rango de precio mercado Autofact</Text3M>
          </CardTitle>
          <RecommendedPrice>
            <Text2>Precio recomendado</Text2>
            <SubCont1>
              <SubCont3 onClick={() => setAutofactStampVisible(true)}>
                <img src={selloAutofact} width={25} height={25} alt="" />
                <Text3M>Sello Autofact</Text3M>
              </SubCont3>
            </SubCont1>
          </RecommendedPrice>

          <ComparativeBar
            TriangleTop1={false}
            TriangleTop2
            TriangleTop3={false}
            TriangleBottom1={false}
            TriangleBottom2={false}
            TriangleBottom3={false}
          />

          <CarPriceCont>
            <Price fontWeight="normal">{`$ ${priceFormat(roundPrice(prices.min_value || 0, 1))} `}</Price>
            <Price fontWeight="bold">{`$ ${priceFormat(roundPrice(prices.autofact_value || 0, 1))} `}</Price>
            <Price fontWeight="normal">{`$ ${priceFormat(roundPrice(prices.max_value || 0, 1))} `}</Price>
          </CarPriceCont>

        </SubCont6>

      </Container>
      <Container>

        <SubCont7>
          <SubCont8>
            <CardFeatures
              title="Kilometraje de referencia"
              value={`${priceFormat(referenceMileage)}`}
              icon={kilometerClockImg}
              iconInfo={false}
            />
          </SubCont8>
          <SubCont8>
            <CardFeatures
              title="Valor residual actual"
              value={`${(actualResidualPrice * 100).toFixed(1)}%`}
              icon={carMoneyImg}
              iconInfo={false}
            />
          </SubCont8>

          <SubCont8>
            <CarFetailsCont>
              <PaddingContainer>
                <img src={checkImg} width={30} height={30} alt="" />
              </PaddingContainer>
              <SubCont4>
                <Text4>Presencia en el mercado</Text4>
                <BlankSpace height="10px" />
                <SubCont5>
                  <Text2M>{publication.status}</Text2M>
                </SubCont5>
                <Text4M>
                  {publicationsList ? publicationsList.length : 0}
                  {' '}
                  publicaciones activas
                </Text4M>

                <BlankSpace height="10px" />
              </SubCont4>
            </CarFetailsCont>
          </SubCont8>

          <SubCont8>
            <CarFetailsCont>
              <CarFetailsSubCont>
                <img src={carSolid} width={30} height={30} alt="" />
              </CarFetailsSubCont>
              <SubCont4>
                <Text4>Ranking modelos más publicados Chileautos</Text4>
                <BlankSpace height="10px" />
                <SubCont5>
                  {
                    rankingChileautos.ranking
                      ? (
                        <Text4M>
                          {rankingChileautos.ranking}
                          /100
                        </Text4M>
                      )
                      : <Text2>Este vehículo no está en el top 100 de los más publicados</Text2>
                    }
                </SubCont5>
                <BlankSpace height="10px" />
              </SubCont4>
            </CarFetailsCont>
          </SubCont8>

          <SubCont8>
            <CardFeatures
              title="Duración promedio publicaciones"
              value={`${Math.round(publication.average_duration || 0)} Días`}
              icon={calendarImg}
              iconInfo={false}
            />
          </SubCont8>

          <LineDSK />

        </SubCont7>
      </Container>
      <ContChart>
        {publicationsList.length > 2 ? <GetChartData /> : ''}
      </ContChart>
    </ContainerParent>
  );
}

function CardFeatures({
  title, value, icon, iconInfo,
}: { title: string, value: string, icon: string, iconInfo: boolean }) {
  return (
    <CarFetailsCont>
      <CarFetailsSubCont>
        <img src={icon} width={25} height={25} alt="" />
      </CarFetailsSubCont>
      <div>
        <CarFetailsSubCont2>
          <Text4>{title}</Text4>
          {iconInfo && (
            <Tooltip title="Valor residual respecto a su precio de lista o valor MSRP (Manufactures Suggested retail price) es el precio referencia al cual se comercializa un vehículo nuevo en su año de fabriación.">
              <PaddingContainer>
                <img src={infoImg} width={16} height={16} alt="" />
              </PaddingContainer>
            </Tooltip>
          )}
        </CarFetailsSubCont2>
        <BlankSpace height="4px" />
        <Text4M>{value}</Text4M>
      </div>
    </CarFetailsCont>
  );
}

function GetChartData() {
  const BAR_CHART_DATA: IData[] = [];
  const screenSize = useMediaQuery();
  let dynamicWitdh = 1;
  if (screenSize <= 1080) dynamicWitdh = 0.9;
  if (screenSize > 1080 && screenSize <= 1280) dynamicWitdh = 0.75;
  if (screenSize > 1280 && screenSize <= 1366) dynamicWitdh = 0.7;
  if (screenSize > 1366 && screenSize <= 1510) dynamicWitdh = 0.6;
  if (screenSize > 1510 && screenSize <= 1725) dynamicWitdh = 0.5;
  if (screenSize > 1725 && screenSize <= 2268) dynamicWitdh = 0.4;
  if (screenSize > 2268) dynamicWitdh = 0.2;

  const WIDTH = screenSize * dynamicWitdh;
  const barCount = 5;

  if (publicationsList.length === 0) {
    return <BarChart data={BAR_CHART_DATA} widthConfig={WIDTH} />;
  }

  const min = Math.min(...publicationsList.map((item) => (item.precio ? item.precio : 0)));
  const max = Math.max(...publicationsList.map((item) => (item.precio ? item.precio : 0)));
  const range = (max - min) / barCount;
  const sections:number[] = [];

  for (let i = 0; i < barCount; i += 1) {
    const sec = min + (range * i);
    const sec2 = ((min + (range * (i + 1))) / 1000000);
    sections.push(sec);
    BAR_CHART_DATA.push({
      label: `${(sec / 1000000).toFixed(2)}MM - ${sec2.toFixed(2)}MM`,
      value: 0,
    });
  }

  publicationsList.forEach((p) => {
    if (p.precio) {
      for (let i = 0; i < barCount; i += 1) {
        if (p.precio >= sections[i] && (i === (barCount - 1) || p.precio < sections[i + 1])) {
          BAR_CHART_DATA[i].value += 1;
        }
      }
    }
  });

  return <BarChart data={BAR_CHART_DATA} widthConfig={WIDTH} />;
}
